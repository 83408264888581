import * as xform from '@api/transformers';
import { nullableDate, nullableDateFromIsoString } from '@utils';
import type { Projects } from '$admin/api/interfaces';
import type { Calls as PC, Members as PM } from '$admin/interfaces/project';

export * from './projects.scheduling';

export const transformProjectCharges = (data: Projects.FetchCharges.Response<string>): Projects.FetchCharges.Response => {
  return {
    ...data,
    items: data.items.map(item => ({
      ...item,
      created: {
        ...item.created,
        on: nullableDate(item.created.on),
      },
    })),
  };
};

export const transformProjectDetailsLegacy = (data: Projects.FetchProjectDetailsLegacy.Response<string>) => {
  return {
    ...data,
    attributes: {
      ...data.attributes,
      targetCompleteDate: nullableDate(data.attributes?.targetCompleteDate),
    },
    object: xform.normalizeWorkspaceObject(data.object),
    createdOn: new Date(data.createdOn),
  };
};

export function transformProjectDetails(data: Projects.FetchProjectDetails.Response<string>): Projects.FetchProjectDetails.Response {
  return {
    ...data,
    project: {
      ...data.project,
      attributes: {
        ...data.project.attributes,
        startDate: nullableDateFromIsoString(data.project.attributes.startDate),
        targetCompleteDate: nullableDateFromIsoString(data.project.attributes.targetCompleteDate),
      },
      createdOn: nullableDate(data.project.createdOn),
    },
    workspace: {
      ...data.workspace,
      object: data.workspace.object
        ? {
          ...data.workspace.object,
          createdOn: nullableDate(data.workspace.object.createdOn),
          expiresOn: nullableDate(data.workspace.object.expiresOn),
        }
        : null,
    },
  };
}

export const transformProjectMember = (data: PM.Item<string>): PM.Item => {
  return {
    ...data,
    calls: {
      ...data.calls,
      scheduling: transformMemberScheduling(data.calls.scheduling),
    },
    pipeline: {
      ...data.pipeline,
      addedOn: new Date(data.pipeline.addedOn),
    },
  };
};

function transformMemberScheduling(data: PM.Item<string>['calls']['scheduling']) {
  if (!data) return null;

  return {
    ...data,
    approvedOn: nullableDate(data.approvedOn),
    createdAt: new Date(data.createdAt),
    screenedOn: nullableDate(data.screenedOn),
  };
}

export function transformCallItem(item: PC.Item<string>): PC.Item {
  return {
    ...item,
    call: {
      ...item.call,
      createdAt: nullableDate(item.call.createdAt),
      timeEnd: nullableDate(item.call.timeEnd),
      timeStart: nullableDate(item.call.timeStart),
    },
  };
}