import { cx } from '@utils';
import { useSurveyThemingPalette } from '@containers/Branding/hooks/useSurveyThemingPalette';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import type { BackProps, WizardNavigationProps, NextProps } from './interfaces';
import styles from './style.css';

export const Back = (props: BackProps) => (
  <div
    className={cx(styles.back, props.className, {
      [styles.backDisabled]: props.disabled,
    })}
    onClick={props.disabled ? undefined : props.onClick}>
    {'< Back'}
  </div>
);

export const NextButton = (props: NextProps) => {
  const { palette, theme } = useSurveyThemingPalette();

  const classes = theme
    ? cx(styles.btn, props.className)
    : cx(styles.next, props.className);

  const indicatorColor = theme
    ? palette.primary.text
    : null;
  const style = theme ? {
    backgroundColor: !props.disabled ? palette.primary.main : `var(--gray-l)`,
    borderColor: !props.disabled ? palette.primary.main : `var(--gray-l)`,
    color: !props.disabled ? palette.primary.text : `var(--pri-01)`,
  } : {};

  return (
    <ButtonActivityIndicator
      className={classes}
      disabled={props.disabled}
      implicitDisable={props.implicitDisable}
      indicatorColor={indicatorColor}
      loading={props.loading}
      onClick={props.onClick}
      color="primary"
      variant="brick"
      style={style}>
      {props.children ?? `Next`}
    </ButtonActivityIndicator>
  );
};

export const CompleteButton = (props: NextProps) => {
  const { palette, theme } = useSurveyThemingPalette();

  const classes = theme
    ? cx(styles.btn, props.className)
    : cx(styles.complete, props.className);

  const indicatorColor = theme
    ? palette.primary.text
    : null;
  const style = theme ? {
    backgroundColor: palette.primary.main,
    borderColor: palette.primary.main,
    color: palette.primary.text,
  } : {};

  return (
    <ButtonActivityIndicator
      className={classes}
      disabled={props.disabled}
      implicitDisable={props.implicitDisable}
      indicatorColor={indicatorColor}
      loading={props.loading}
      onClick={props.onClick}
      variant="brick"
      style={style}>
      Complete
    </ButtonActivityIndicator>
  );
};

export const WizardNavigation = (props: WizardNavigationProps) => (
  <div className={cx(styles.navigation, props.className)}>
    <div>
      {props.renderBack && props.renderBack()}
    </div>
    <div>
      {props.renderNext && props.renderNext()}
    </div>
  </div>
);

export type { WizardNavigationProps };
export default WizardNavigation;