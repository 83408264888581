import type { IBaseWorkspaceObjectItem } from '@/types/workspace.table';
import type { IFilesTable } from './interfaces';

export function reducer<I extends IBaseWorkspaceObjectItem>(
  state: IFilesTable.State<I>,
  action: IFilesTable.Action<I>,
  sorter: IFilesTable.ISorter<I> = sortItems<I>
): IFilesTable.State<I> {
  switch (action.type) {
    case 'load': {
      const totalCount = action.items.length;
      const pageIndex = 0;
      return {
        ...state,
        items: Array.from(sorter(action.items, state.sortBy)),
        pageIndex,
        totalCount,
        ...computePageMovement(pageIndex, state.pageSize, totalCount),
      };
    }
    case 'update-sort-by': {
      const sortBy = action.sortBy;
      return {
        ...state,
        pageIndex: 0,
        sortBy,
        items: Array.from(sorter(state.items, sortBy)),
      };
    }
    case 'update-page-size': {
      if (action.pageSize === state.pageSize) return state;

      const pageSize = action.pageSize;
      return {
        ...state,
        pageIndex: 0,
        pageSize,
        ...computePageMovement(state.pageIndex, pageSize, state.totalCount),
      };
    }
    case 'next-page': {
      const pageIndex = state.pageIndex + 1;
      return {
        ...state,
        pageIndex,
        ...computePageMovement(pageIndex, state.pageSize, state.totalCount),
      };
    }
    case 'previous-page': {
      const pageIndex = state.pageIndex - 1;
      return {
        ...state,
        pageIndex,
        ...computePageMovement(pageIndex, state.pageSize, state.totalCount),
      };
    }
    default: return state;
  }
}

export function getInitialState<I extends IBaseWorkspaceObjectItem>(): IFilesTable.State<I> {
  return {
    items: [],
    sortBy: [{ field: 'createdOn', desc: true }],
    pageSize: 25,
    pageIndex: 0,
    totalCount: 0,
    canPreviousPage: false,
    canNextPage: false,
  };
}

export function getPagedItems<I>(items: I[], pageIndex: number, pageSize: number) {
  const start = pageIndex * pageSize;
  return Array.from(items.slice(start, start + pageSize));
}

function computePageMovement(pageIndex: number, pageSize: number, totalCount: number) {
  return {
    canPreviousPage: pageIndex > 0,
    canNextPage: pageSize * (pageIndex + 1) < totalCount,
  };
}

const sortItems = <I extends IFilesTable.ISortItem>(items: I[], sortBy: IFilesTable.SortingRule[]): I[] => {
  const sort = sortBy[0];
  switch (sort.field) {
    case 'name':
      return items.sort((a, b) => !sort.desc
        ? a.object.name.localeCompare(b.object.name)
        : b.object.name.localeCompare(a.object.name));

    case 'bookmark':

      return items.sort((a, b) =>
        !sort.desc ? Number(a.bookmarked) - Number(b.bookmarked) : Number(b.bookmarked) - Number(a.bookmarked)

      );

    case 'owner':
      return items.sort((a, b) => !sort.desc
        ? a.meta.owner.displayName.localeCompare(b.meta.owner.displayName)
        : b.meta.owner.displayName.localeCompare(a.meta.owner.displayName));
    case 'createdOn':
      return items.sort((a, b) => !sort.desc
        ? a.object.createdOn.getTime() - b.object.createdOn.getTime()
        : b.object.createdOn.getTime() - a.object.createdOn.getTime());

    case 'tags':
      return items.sort((a, b) => (a.tags[0]?.name ?? '').localeCompare(b.tags[0]?.name ?? '') * (sort.desc ? -1 : 1));

    default: return items;
  }
};