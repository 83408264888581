import { useMutation, useQuery } from '@tanstack/react-query';
import { getBrandAccess, updateBrandAccess } from '@api/workspaces.brand';
import type { WorkspaceUnifiedAccess } from '@/types';
import { BrandAccessQueryContext, BrandAccessMutationContext, BrandAccessIdContext } from './Context';

type Props = IWorkspaceBrandId & ChildrenProps;

export const BrandAccessContainer = (props: Props) => {
  const query = useQuery({
    queryKey: ['get:brand-access', props.brandId],
    queryFn: () => getBrandAccess({ brandId: props.brandId }),
    refetchOnWindowFocus: false,
  });

  const mutation = useMutation({
    mutationKey: ['update:brand-access', props.brandId],
    mutationFn: (items: WorkspaceUnifiedAccess[]) => updateBrandAccess({ brandId: props.brandId, items }),
    onSuccess: () => query.refetch(),
  });

  return (
    <BrandAccessIdContext.Provider value={props.brandId}>
      <BrandAccessQueryContext.Provider value={query}>
        <BrandAccessMutationContext.Provider value={mutation}>
          {props.children}
        </BrandAccessMutationContext.Provider>
      </BrandAccessQueryContext.Provider>
    </BrandAccessIdContext.Provider>
  );
};