import { useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import * as api from '@api';
import { useInvalidateProjectFilesQuery } from '@/utils/api';
import { WorkspaceObjectType } from '@/enums';
import {
  GroupEntityTagsContext,
  GroupEntityTagsDispatchContext,
  GroupEntityTagsFetchContext,
  GroupEntityTagsLoadingContext,
  GroupEntityTagsUpdateContext,
} from './Context';
import type { GroupTagsContextValue, GroupTagUpdateItem } from './interfaces';
import { usePublishClientTags, useSyncClientObjectTagsMutation } from './hooks';

type Props =
  ITranscriptId &
  ChildrenProps;

export default function GroupTranscriptTagsContainer({ transcriptId, ...props }: Props) {
  const [state, dispatch] = useState<GroupTagsContextValue>(GroupTranscriptTagsContainer.initialState);
  const invalidateProjectFiles = useInvalidateProjectFilesQuery();

  const { isSuccess, refetch, data } = useQuery({
    queryKey: ['get/group:tags:transcript', { transcriptId }],
    queryFn: () => {
      return api.tags.group.getTranscriptTags({ transcriptId });
    },
  });

  useEffect(() => {
    if (data?.tags)
      dispatch(data.tags);
  }, [data?.tags]);

  const [postSave, SyncModal] = usePublishClientTags();
  const syncTagsMutation = useSyncClientObjectTagsMutation({ entityId: transcriptId, entityType: WorkspaceObjectType.CallTranscript });

  const { mutateAsync } = useMutation({
    mutationFn: (tags: GroupTagUpdateItem[]) => {
      return api.tags.group.updateTranscriptTags({
        transcriptId,
        tags,
      });
    }, onSuccess: res => {
      invalidateProjectFiles();
      postSave({ prevTags: state, ...res });
      dispatch(res.tags);
    },
  });

  return (
    <GroupEntityTagsContext.Provider value={state}>
      <GroupEntityTagsLoadingContext.Provider value={!isSuccess}>
        <GroupEntityTagsFetchContext.Provider value={refetch}>
          <GroupEntityTagsUpdateContext.Provider value={mutateAsync}>
            <GroupEntityTagsDispatchContext.Provider value={dispatch}>
              {props.children}
              <SyncModal mutation={syncTagsMutation} />
            </GroupEntityTagsDispatchContext.Provider>
          </GroupEntityTagsUpdateContext.Provider>
        </GroupEntityTagsFetchContext.Provider>
      </GroupEntityTagsLoadingContext.Provider>
    </GroupEntityTagsContext.Provider>
  );
}

const initial: GroupTagsContextValue = [];

GroupTranscriptTagsContainer.initialState = initial;

export { GroupTranscriptTagsContainer };