import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format, isBefore } from 'date-fns';
import { useGroupEntityTags, useGroupEntityTagsUpdate } from '@/containers/GroupTags/hooks';
import { DatePicker } from '@/components/DatePicker';
import Pencil from '@/components/icons/Pencil';
import { GroupSidebarTags } from '@/components/GroupSidebarTags';
import { useWorkspaceFileState } from '@/containers/Workspace.File.Preview/hooks';
import { UpdateFilePublishedOnContext } from '@/containers/Workspace.File/Context';
import { UpdateFilePublishedOnContainer } from '@/containers/Workspace.File/UpdateFilePublishedDateContainer';
import { UpdateObjectExpirationContext } from '@containers/WorkspaceObject/Context';
import { UpdateObjectExpirationContainer } from '@containers/WorkspaceObject/UpdateObjectExpirationContainer';
import { FormButtons } from '@/presentation/FormButtons';
import * as Tab from '@/presentation/Workspace.Tab.Pane';
import { useHasGroupFeature } from '@/store/hooks';
import { useConditionalRender } from '@/utils';
import styles from './style/Tab.Details.css';

const StateDependent = () => {
  const { file, object } = useWorkspaceFileState();
  const ctx = {
    expiration: useContext(UpdateObjectExpirationContext),
    publishing: useContext(UpdateFilePublishedOnContext),
  };

  const [editingPublished, setEditingPublished] = useState<boolean>(false);
  const [editingExpiration, setEditingExpiration] = useState<boolean>(false);

  const filteredAuthors = file.authors.filter(f => !!f.name || !!f.affiliation);

  return (
    <Tab.Panes.Container>
      <Tab.Pane.Pane>
        <Tab.Pane.Title>Details</Tab.Pane.Title>
        <Tab.Items.Container>
          <Tab.Item.Container>
            <Tab.Item.TextLabel>Name</Tab.Item.TextLabel>
            <Tab.Item.Value>{object.name}</Tab.Item.Value>
          </Tab.Item.Container>

          <Tab.Item.Container>
            <Tab.Item.TextLabel>Owner</Tab.Item.TextLabel>
            <Tab.Item.Value>{object.owner.name}</Tab.Item.Value>
          </Tab.Item.Container>

          <Tab.Item.Container>
            <Tab.Item.TextLabel>Authors</Tab.Item.TextLabel>
            <Tab.Item.Value>
              {filteredAuthors.length ? (
                filteredAuthors.map(({ name, affiliation }, index) => (
                  <div key={index}>
                    {[name, affiliation].filter(Boolean).join(', ')}
                  </div>
                ))
              ) : (
                <>None</>
              )}
            </Tab.Item.Value>
          </Tab.Item.Container>

          <Tab.Item.Container>
            <Tab.Item.TextLabel>Title</Tab.Item.TextLabel>
            <Tab.Item.Value>{file.title || 'Unknown'}</Tab.Item.Value>
          </Tab.Item.Container>

          <Tab.Items.Row>
            <Tab.Item.Container className={styles.field}>
              <Tab.Item.TextLabel>Uploaded On</Tab.Item.TextLabel>
              <Tab.Item.Value>{format(object.createdOn, 'M/d/yyyy')}</Tab.Item.Value>
            </Tab.Item.Container>

            <PublishingStatus
              expiresOn={object.expiresOn}
              publishedOn={file.publishedOn} />
          </Tab.Items.Row>

          <Tab.Items.Row>
            <PublishedDate
              editing={editingPublished}
              setEditing={setEditingPublished}
              value={file.publishedOn}
              onCancel={() => {
                setEditingPublished(false);
              }}
              onSubmit={value => {
                ctx.publishing.update(value);
                setEditingPublished(false);
              }} />

            <Expiration
              editing={editingExpiration}
              setEditing={setEditingExpiration}
              value={object.expiresOn}
              onCancel={() => {
                setEditingExpiration(false);
              }}
              onSubmit={value => {
                ctx.expiration.update({
                  expiresOn: value,
                });
                setEditingExpiration(false);
              }} />
          </Tab.Items.Row>
        </Tab.Items.Container>
      </Tab.Pane.Pane>
      <TagPane />
    </Tab.Panes.Container>
  );
};

function TagPane() {
  const $Tags = useConditionalRender(useHasGroupFeature('userTagging'));

  const tags = useGroupEntityTags();
  const updateTags = useGroupEntityTagsUpdate();

  return (
    <$Tags>
      <Tab.Pane.Pane>
        <GroupSidebarTags
          tags={tags}
          onSave={updateTags} />
      </Tab.Pane.Pane>
    </$Tags>
  );
}

type PublishedDateProps = {
  editing: boolean;
  setEditing: (value: boolean) => void;
  onCancel: () => void;
  onSubmit: (value: Date) => void;
  value: Date;
};

const PublishedDate = ({
  setEditing,
  onCancel,
  onSubmit,
  editing,
  value,
}: PublishedDateProps) => {
  return (
    <Tab.Item.Container className={styles.field}>
      <Tab.Item.TextLabel>Published On</Tab.Item.TextLabel>
      <Tab.Item.Value>
        {!editing && (
          <div className={styles.dateFieldValue} onClick={() => setEditing(true)}>
            {format(value, 'M/d/yyyy')}
            <div className={styles.pencil}>
              <Pencil size={16} />
            </div>
          </div>
        )}
        {editing && (
          <EditingPublishedDate
            editing={editing}
            value={value}
            onSubmit={onSubmit}
            onCancel={onCancel}
            setEditing={setEditing} />
        )}
      </Tab.Item.Value>
    </Tab.Item.Container>
  );
};

type DatePickerState = {
  value: Date;
};

const EditingPublishedDate = ({
  onCancel,
  onSubmit,
  value,
}: PublishedDateProps) => {
  const [state, setState] = useState<DatePickerState>({
    value,
  });

  return (
    <>
      <div className={styles.dateFieldValue}>
        <div className={styles.datePicker}>
          <DatePicker
            inline={false}
            open={true}
            onChange={value => setState({ value })}
            placeholderText="Select a date"
            selected={state.value} />
        </div>
        <FormButtons
          className={styles.btns}
          size={16}
          disabled={false}
          onCancel={onCancel}
          onSubmit={() => onSubmit(state.value)} />
      </div>
    </>
  );
};

type ExpirationProps = {
  editing: boolean;
  setEditing: (value: boolean) => void;
  onCancel: () => void;
  onSubmit: (value: Date) => void;
  value: Date;
};

const Expiration = ({
  setEditing,
  onCancel,
  onSubmit,
  editing,
  value,
}: ExpirationProps) => {
  return (
    <Tab.Item.Container className={styles.field}>
      <Tab.Item.TextLabel>Expiration Date</Tab.Item.TextLabel>
      <Tab.Item.Value>
        {!editing && (
          <div className={styles.dateFieldValue} onClick={() => setEditing(true)}>
            {value ? format(value, 'M/d/yyyy') : 'None'}
            <div className={styles.pencil}>
              <Pencil size={16} />
            </div>
          </div>
        )}
        {editing && (
          <EditingExpiration
            editing={editing}
            value={value}
            onSubmit={onSubmit}
            onCancel={onCancel}
            setEditing={setEditing} />
        )}
      </Tab.Item.Value>
    </Tab.Item.Container>
  );
};

const EditingExpiration = ({ onCancel, onSubmit, value }: ExpirationProps) => {
  const [state, setState] = useState<DatePickerState>({ value });

  return (
    <>
      <div className={styles.dateFieldValue}>
        <div className={styles.datePicker}>
          <DatePicker
            inline={false}
            open={true}
            onChange={value => setState({ value })}
            placeholderText="Select a date"
            selected={state.value} />
        </div>
        <FormButtons
          className={styles.btns}
          size={16}
          disabled={false}
          onCancel={onCancel}
          onSubmit={() => onSubmit(state.value)} />
      </div>
    </>
  );
};

type PublishingStatusProps = {
  expiresOn: Date;
  publishedOn: Date;
};

const PublishingStatus = (props: PublishingStatusProps) => {
  const value = props.expiresOn && !isBefore(new Date(), props.expiresOn)
    ? `Expired`
    : props.publishedOn !== null
      ? `Published`
      : `Draft`;

  return (
    <Tab.Item.Container>
      <Tab.Item.TextLabel>Publishing Status</Tab.Item.TextLabel>
      <Tab.Item.Value>{value}</Tab.Item.Value>
    </Tab.Item.Container>
  );
};

export const FileTabDetails = () => {
  const params = useParams<Stringify<IProjectId>>();
  const { file, object } = useWorkspaceFileState();

  const projectId = params?.projectId
    ? +params.projectId
    : undefined;

  return (
    <UpdateFilePublishedOnContainer
      file={file}
      workspaceId={object.workspaceId}>
      <UpdateObjectExpirationContainer
        fileId={file.id}
        objectId={object.id}
        projectId={projectId}
        workspaceId={object.workspaceId}>
        <StateDependent />
      </UpdateObjectExpirationContainer>
    </UpdateFilePublishedOnContainer>
  );
};
