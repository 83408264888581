import { pathname } from '@consts';
import { RouteAuth } from '@enums';
import { StateRouter } from '@routes/Routers';
import type * as Routing from '@routes/interfaces';
import { Role } from '@enums';
import { CallEnhancementQueue } from '@screens/Call.Enhancement.Queue';
import { AnalystMain, ComplianceMainContainer } from '@screens/GroupMain';
import { BrandInsightsChat } from '@screens/BrandInsights.Chat';
import * as Member from '@screens/Main.Member';
import { TransientMain } from '@screens/TransientMain';
import * as utils from '@utils';
import { AdminRedirector } from './AdminRedirector';

const rootConfig = {
  exact: true,
  path: [pathname.Home, '/'],
};

const consultant: Routing.Route[] = [
  {
    ...rootConfig,
    component: Member.Main,
    roles: [
      Role.Expert,
    ],
  },
  {
    exact: true,
    component: Member.Main,
    path: `${pathname.HOME}/new`,
    roles: [
      Role.Expert,
    ],
  },
];

const compliance: Routing.Route[] = [
  {
    ...rootConfig,
    component: ComplianceMainContainer,
    roles: [
      Role.FirmCompliance,
    ],
  },
];

const analyst: Routing.Route[] = [
  {
    ...rootConfig,
    component: AnalystMain,
    roles: [
      Role.FirmAnalyst,
    ],
  },
];

const transient: Routing.Route[] = [
  {
    ...rootConfig,
    component: TransientMain,
    roles: [
      Role.FirmTransient,
    ],
  },
];

const admin: Routing.Route[] = [
  {
    ...rootConfig,
    component: AdminRedirector,
    roles: [],
  },
];

const datasteward: Routing.Route[] = [
  {
    ...rootConfig,
    component: CallEnhancementQueue,
    roles: [
      Role.DataSteward,
    ],
  },
];

const insightsChatOnly: Routing.Route[] = [
  {
    ...rootConfig,
    component: BrandInsightsChat,
    roles: [
      Role.InsightsChatOnly,
    ],
  },
];

const validator = (state: Store.State) => {
  if (utils.hasInsightsChatOnlyRole(state.user) && state.group.features.insightsChat) return insightsChatOnly;
  else if (utils.hasDataStewardRole(state.user)) return datasteward;
  else if (utils.hasTransientRole(state.user)) return transient;
  else if (utils.hasComplianceRole(state.user)) return compliance;
  else if (utils.hasClientRole(state.user)) return analyst;
  else if (utils.hasConsultantRole(state.user)) return consultant;

  return [];
};

export const router = StateRouter(validator, {
  auth: RouteAuth.Authenticated,
  path: pathname.Home,
});