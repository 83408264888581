import { useCallback } from 'react';
import {
  ReactComponentExtension,
  useRemirror,
} from '@remirror/react';
import type { InvalidContentHandler, NodeExtension } from '@remirror/core';
import { DocExtension } from '@remirror/extension-doc';
import type { FindOptions } from '@remirror/extension-find';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { TranscribeRT } from '@/types';
import {
  HighlightExtension,
  KaraokeExtension,
  MonologueDetailsExtension,
  MonologueTextExtension,
  RedactionExtension,
  TimestampExtension,
  TextReplaceExtension,
  ConfidenceExtension,
  MyFindExtension,
  AutoCorrectExtension,
} from '../Extension';
import { TaggedMomentBuilderMonologueDetails } from '../MonologueDetails';

type Props = {
  transcript: TranscribeRT.DocumentNode;
  MonologueDetailsComponent?: NodeExtension['ReactComponent'];
};

const findOptions: FindOptions = {
  //activeDecoration: { style: 'background-color: yellow;' },
};

export const useRichTextTranscript = ({ MonologueDetailsComponent, transcript }: Props) => {

  const isInternalUser = useIsInternalUser();

  const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers, error }) => {
    console.error('Invalid Content', invalidContent);
    console.error(error);

    // findEmptyTextNodes(json as TranscribeRT.DocumentNode);

    return transformers.remove(json, invalidContent);
  }, []);

  const extensions = useCallback(() => [
    new DocExtension({}),
    new HighlightExtension<TranscribeRT.Highlight>({}),
    new KaraokeExtension(),
    new MonologueDetailsExtension(MonologueDetailsComponent),
    new MonologueTextExtension(),
    new ReactComponentExtension({}),
    new RedactionExtension(),
    new TimestampExtension(),
    new TextReplaceExtension(),
    new MyFindExtension(findOptions),
    new ConfidenceExtension({ enabled: isInternalUser }),
    new AutoCorrectExtension({ enabled: isInternalUser }),
  ].filter(Boolean), [isInternalUser, MonologueDetailsComponent]);

  return useRemirror({
    content: transcript,
    extensions,
    onError,
  });
};

function findEmptyTextNodes(json: TranscribeRT.DocumentNode) {
  for (const node of json.content) {
    if (node.type === TranscribeRT.NodeType.MonologueText) {
      for (let i = 0; i < node.content.length; i++) {
        const tNode = node.content[i];
        if (tNode.type === TranscribeRT.NodeType.Text) {
          if (tNode.text === '') {
            console.error('Empty text node found', i);
            console.log(node);
          }
        }
      }
    }
  }
}

export const useTaggedMomentBuilderRichTextEditor = ({ transcript }: Pick<Props, 'transcript'>) => {
  const onError: InvalidContentHandler = useCallback(({ json, invalidContent, transformers }) => {
    console.error('Invalid Content', invalidContent);
    return transformers.remove(json, invalidContent);
  }, []);

  return useRemirror({
    content: transcript,
    extensions: () => [
      new DocExtension({}),
      new HighlightExtension<TranscribeRT.Highlight>({}),
      new MonologueDetailsExtension(TaggedMomentBuilderMonologueDetails),
      new MonologueTextExtension(),
      new ReactComponentExtension({}),
      new TimestampExtension(),
      new MyFindExtension({}),
    ].filter(Boolean),
    onError,
  });
};