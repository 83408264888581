import { format } from 'date-fns';
import { UAParser } from 'ua-parser-js';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import MobileOffIcon from '@mui/icons-material/MobileOff';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { useMemo } from 'react';
import type { Participant as LiveParticipant, Participant, ParticipantWeb } from '@/types/conferences.live';
import { Tooltip } from '@presentation/Tooltip';
import styles from './style/Bar.Right.Participants.css';

type ParticipantDeviceInfoProps = {
  participant: LiveParticipant;
};

type ContentProps = {
  participant: ParticipantWeb;
};

export function ParticipantDeviceInfo({ participant }: ParticipantDeviceInfoProps) {
  if (participant.type !== 'web' || !participant.deviceInfo) return null;

  return <Content participant={participant} />;
}

function Content({ participant }: ContentProps) {
  const hasError = participant.deviceInfo.audio.error || participant.deviceInfo.video.error;

  const Icon = useMemo(() => {
    const isMobile = parseUserAgent(participant.userAgent)?.device?.type === 'mobile';

    if (isMobile) {
      return hasError ? MobileOffIcon : SmartphoneIcon;
    } else {
      return hasError ? PhonelinkOffIcon : PhonelinkIcon;
    }

  }, [hasError, participant.userAgent]);

  const ToolTipContent = (
    <div>
      <div>Audio</div>
      <DeviceInfo info={participant.deviceInfo.audio} />
      <div>Video</div>
      <DeviceInfo info={participant.deviceInfo.video} />
      <UserAgentInfo userAgent={participant.userAgent} />
      <div>Last updated on {format(new Date(participant.deviceInfo.lastEventTs), 'p')}</div>
    </div>
  );

  return (
    <Tooltip
      title={ToolTipContent}
      placement='top'>
      <Icon className={styles.deviceIcon} />
    </Tooltip>
  );
}

type DeviceInfo = ParticipantWeb['deviceInfo']['audio'];

function DeviceInfo({ info }: { info: DeviceInfo }) {
  if (info.error) {
    return `Error: ${info.error}`;
  } else if (info.isPrompting) {
    return `Waiting on user permission prompt`;
  } else {
    return `Ready: ${info.deviceList.length} devices available`;
  }
}

type UserAgentProps = {
  userAgent: string;
};

const UserAgentInfo = (props: UserAgentProps) => {

  if (!props.userAgent) return null;

  const userAgent = parseUserAgent(props.userAgent);
  if (!userAgent) {
    return <div>Invalid User Agent</div>;
  }

  return (
    <div>
      <div>Browser: {userAgent.browser.name} ({userAgent.browser.version})</div>
      <div>Device: {userAgent.device.type ?? 'desktop'}</div>
    </div>
  );
};

function parseUserAgent(userAgent: string) {
  let result: UAParser.IResult = null;
  try {
    result = new UAParser(userAgent).getResult();
  } catch (e) {
    return null;
  }

  return result;
}