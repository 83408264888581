import { useQuery, useMutation } from '@tanstack/react-query';
import * as QK from '@consts/querykey';
import { ProjectAccessMutationContext, ProjectAccessQueryContext } from '@/components/Project.Access/Context';
import * as api from '$admin/api';
import type { Entry } from './interfaces';

type Props = {
  children: React.ReactNode;
} & IProjectId;

export const ProjectAccessContainer = (props: Props) => {
  const query = useQuery({
    queryKey: [QK.Admin.Projects.Access.Get, props?.projectId],
    queryFn: () => {
      return api.projects.access.getProjectAccessUsers({
        projectId: props?.projectId,
      });
    },
    enabled: !!props?.projectId,
    refetchOnWindowFocus: false,
  });

  const mutation = useMutation({
    mutationKey: [
      QK.Admin.Projects.Access.Put,
      props?.projectId,
    ], mutationFn: (items: Entry[]) => {
      return api.projects.access.updateProjectAccessUsers({
        items,
        projectId: props.projectId,
      });
    },
    onSuccess: () => query.refetch(),
  });

  return (
    <ProjectAccessQueryContext.Provider value={query}>
      <ProjectAccessMutationContext.Provider value={mutation}>
        {props.children}
      </ProjectAccessMutationContext.Provider>
    </ProjectAccessQueryContext.Provider>
  );
};

ProjectAccessContainer.displayName = 'Project.Access.Container';