import styled from '@emotion/styled';

type Props = {
  className?: string;
} & ChildrenProps;

export function Container({ className, children }: Props) {
  return (
    <Root className={className}>
      {children}
    </Root>
  );
}

const Root = styled.div`
  box-sizing: border-box;

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Row = ({ children, className }: Props) => {
  return (
    <RowItem className={className}>
      {children}
    </RowItem>
  );
};

const RowItem = styled.div`
  display: flex;

  > *:not(:last-child) {
    width: 250px;
  }
`;