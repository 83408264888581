import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';
import { format } from 'date-fns';
import { Checkbox } from '@/components/Checkbox';
import styles from './style/Project.Access.Modal.css';

type Props = {
  calls: {
    callId: number;
    startTime: Date;
    endTime: Date;
    respondent: string;
  }[];
  selectedCallIds: number[];
  setSelectedCallIds: Dispatch<SetStateAction<number[]>>;
};

export const SelectUpcomingCalls = ({ selectedCallIds, setSelectedCallIds, calls }: Props) => {
  const toggleCallSelect = useCallback((callId: number) => {
    setSelectedCallIds(old => {
      if (old.includes(callId)) {
        return old.filter(x => x != callId);
      } else {
        return [...old, callId];
      }
    });
  }, [setSelectedCallIds]);

  const toggleMasterCallSelect = useCallback(() => {
    setSelectedCallIds(old => {
      if (old.length === calls.length) {
        return [];
      } else {
        return calls.map(c => c.callId);
      }
    });
  }, [calls, setSelectedCallIds]);

  return (
    <div className={styles.backfillContainer}>
      <table className={styles.backfillTable}>
        <thead>
          <tr>
            <th>
              <Checkbox
                checked={selectedCallIds.length === calls.length}
                indeterminate={(!!selectedCallIds.length && selectedCallIds.length != calls.length)}
                onClick={toggleMasterCallSelect} />
            </th>
            <th>Date</th>
            <th>Time</th>
            <th>Respondent</th>
          </tr>
        </thead>
        <tbody>
          {calls.map(c => (
            <tr key={c.callId}>
              <td><Checkbox checked={selectedCallIds.includes(c.callId)} onChange={() => toggleCallSelect(c.callId)} /></td>
              <td>{c.startTime ? format(c.startTime, 'LLL do') : ' - '}</td>
              <td>{c.startTime ? `${format(c.startTime, 'p')} - ${format(c.endTime, 'p')}` : ' - '}</td>
              <td>{c.respondent}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};