import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useContext } from 'react';
import type { BackfillBrandProjectAccess, GetBrandAccess, UpdateBrandAccess } from '@/services/api/interfaces/workspace.brand';
import type { StepperActions } from '@/utils';
import { createNamedContext } from '@/utils';
import type { WorkspaceUnifiedAccess } from '@/types';
import type { IUser, UpcomingCall, AccessMutationResult } from './interfaces';

type BrandAccessStepperContextValue = {
  actions: StepperActions & { forceNext: () => void };
  items: WorkspaceUnifiedAccess[];
  onSuccess: () => void;
  onClose: () => void;
  users: AccessMutationResult;
  setUsers: (users: AccessMutationResult) => void;
  upcomingCalls: UpcomingCall[];
  setUpcomingCalls: (calls: UpcomingCall[]) => void;
  projectMutationResult: BackfillBrandProjectAccess.Response;
  setProjectMutationResult: (result: BackfillBrandProjectAccess.Response) => void;
};

type BrandAccessQueryContextValue = UseQueryResult<GetBrandAccess.Response>;
type BrandAccessMutationContextValue = UseMutationResult<UpdateBrandAccess.Response, unknown, WorkspaceUnifiedAccess[]>;

export const BrandAccessIdContext = createNamedContext<IWorkspaceBrandId['brandId']>(undefined, 'Brand.Access.ID.Context');
export const BrandAccessStepperContext = createNamedContext<BrandAccessStepperContextValue>(undefined, 'Brand.Access.StepperContext');
export const BrandAccessQueryContext = createNamedContext<BrandAccessQueryContextValue>(undefined, 'Brand.Access.QueryContext');
export const BrandAccessMutationContext = createNamedContext<BrandAccessMutationContextValue>(undefined, 'Brand.Access.MutationContext');

export const useBrandId = () => useContext(BrandAccessIdContext);