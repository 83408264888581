import { useState, useCallback } from 'react';
import type { UseMutationResult } from '@tanstack/react-query';
import type { Group, GroupTag } from '@/types';
import type { UseModalProps } from '@/components/Modal';
import { Modal, Header, useModal } from '@/components/Modal';
import { Checkbox } from '@/components/Checkbox';
import Button, { Buttons } from '@/components/Button';
import { ButtonActivityIndicator } from '@/presentation';
import { PublishTagTable } from './PublishTagTable';
import styles from './styles.css';

type PublishParams = {
  tags: GroupTag[];
  groupIds: number[];
};

export type ClientTagPublishModalProps = {
  tags: GroupTag[];
  groups: Group[];
  mutation: UseMutationResult<unknown, unknown, PublishParams>;
} & UseModalProps;

export const ClientTagPublishModal = ({ tags, groups, mutation, ...modalProps }: ClientTagPublishModalProps) => {
  const [tagIds, setTagIds] = useState(tags.map(t => t.id));
  const [accountIds, setAccountIds] = useState(groups.map(g => g.id));

  const toggleTag = useCallback((id: number) => {
    setTagIds(old => {
      if (old.includes(id)) {
        return old.filter(x => x !== id);
      } else {
        return [...old, id];
      }
    });
  }, []);

  const toggleAccount = useCallback((id: number) => {
    setAccountIds(old => {
      if (old.includes(id)) {
        return old.filter(x => x !== id);
      } else {
        return [...old, id];
      }
    });
  }, []);

  const onPublish = useCallback(() => {
    mutation.mutateAsync({
      groupIds: accountIds,
      tags: tags.filter(t => tagIds.includes(t.id)),
    }).then(modalProps.onClose);
  }, [accountIds, modalProps.onClose, mutation, tagIds, tags]);

  return (
    <Modal {...modalProps}>
      <Header>Publish Tags to Client Accounts?</Header>
      <div className={styles.subtitle}>Do you want to add the following tags to client accounts associated to this project?</div>
      <PublishTagTable
        groups={groups}
        tags={tags}
        selectedTagIds={tagIds}
        setSelectedTagIds={setTagIds}
        selectedGroupIds={accountIds}
        setSelectedGroupIds={setAccountIds} />
      <Buttons>
        <Button.Destructive
          variant='brick'
          onClick={modalProps.onClose}>
          Do Not Publish
        </Button.Destructive>
        <ButtonActivityIndicator
          onClick={onPublish}
          loading={mutation.isLoading}
          implicitDisable={false}
          disabled={!tagIds.length || !accountIds.length}>
          Publish Tags
        </ButtonActivityIndicator>
      </Buttons>
    </Modal>
  );
};

export const useClientTagPublishModal = () => useModal(ClientTagPublishModal);