import { useCallback, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import type { ProjectType } from '@enums';
import { SurveyType } from '@enums';
import { ProjectSurveyBuilderState, SurveyQuotasGoalContext } from '@containers/SurveyBuilder';
import { useSurveyBuilderState, useCanSaveSurvey } from '@containers/SurveyBuilder/hooks';
import ActivityIndicator from '@/components/ActivityIndicator';
import * as api from '$admin/api';
import { EditContext, QueryContext } from './Context';
import { useFetchProjectSurvey } from './hooks/useFetchProjectSurvey';
import { useSurveyQuery } from './hooks/useSurveyQuery';
import { useProjectSurveysContext } from './ProjectSurveysContainer';

type Props = {
  quota: {
    goal: number;
  };
  project: {
    id: number;
    projectType: ProjectType;
  };
} & ISurveyId & ChildrenProps;

export function ProjectSurveyContainer({ project, surveyId, quota, children }: Props) {

  return (
    <QueryContainer projectId={project.id} surveyId={surveyId}>
      <SurveyBuilderContainer project={project}>
        <SurveyQuotasGoalContext.Provider value={quota.goal}>
          <EditContainer projectId={project.id}>
            {children}
          </EditContainer>
        </SurveyQuotasGoalContext.Provider>
      </SurveyBuilderContainer>
    </QueryContainer>
  );
}

function QueryContainer({ children, projectId, surveyId }: ChildrenProps & IProjectId & ISurveyId) {
  const query = useFetchProjectSurvey({ projectId, surveyId });

  //Always refetch the latest survey when we change the selected survey
  useEffect(() => {
    if (!query.isFetching) {
      query.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyId]);

  const ready = !query.isFetching;

  if (query.isFetching) return <ActivityIndicator />;

  return (
    <QueryContext.Provider value={[ready, query]}>
      {children}
    </QueryContext.Provider>
  );
}

function SurveyBuilderContainer({ children, project }: Pick<Props, 'children' | 'project'>) {
  const [, query] = useSurveyQuery();
  const { query: surveysQuery } = useProjectSurveysContext();

  return (
    <ProjectSurveyBuilderState
      projectType={project.projectType}
      initialValue={query.data.survey}
      excludeSurveyTypes={surveysQuery.data.surveys.filter(t => t.typeId === SurveyType.Project).map(s => s.typeId)}>
      {children}
    </ProjectSurveyBuilderState>
  );
}

function EditContainer({ projectId, children }: Pick<Props, 'children'> & IProjectId) {
  const canSaveSurvey = useCanSaveSurvey();
  const [state, dispatch] = useSurveyBuilderState();
  const { query: surveysQuery } = useProjectSurveysContext();
  const [, query] = useSurveyQuery();

  const save = useCallback(() => {
    return api.projects.surveys.updateSurvey({
      projectId,
      survey: {
        alternateImageExercises: state.survey.alternateImageExercises,
        classifications: state.survey.classifications,
        items: state.survey.items,
        language: state.survey.language,
        logic: state.survey.logic,
        messages: state.survey.messages,
        sections: state.survey.sections,
        surveyId: state.survey.surveyId,
        surveyDetails: state.survey.surveyDetails,
        questions: state.survey.questions,
        quotas: state.survey.quotas,
        tagging: state.survey.tagging,
        template: state.survey.template,
      },
      draftVersionId: state.draft.surveyVersionId,
    })
      .then(() => {
        return query.refetch()
          .then(result => {
            surveysQuery.refetch();
            dispatch({
              type: 'reset-survey',
              value: result.data.survey,
            });
            return result.data;
          });
      });
  }, [
    dispatch,
    query,
    surveysQuery,
    projectId,
    state.draft.surveyVersionId,
    state.survey,
  ]);

  const cancel = useCallback(() => {
    return new Promise<void>(resolve => {
      dispatch({
        type: 'reset-survey',
        value: state.savedSurvey,
      });
      resolve();
    });
  }, [state.savedSurvey, dispatch]);

  const deleteMutation = useMutation(
    {
      mutationKey: ['delete-survey', state.savedSurvey.surveyId, projectId],
      mutationFn: () => {
        return api.projects.surveys.deleteSurvey({ surveyId: state.savedSurvey.surveyId, projectId });
      }, onSuccess: () => {
        surveysQuery.refetch();
      },
    });

  return (
    <EditContext.Provider value={[canSaveSurvey, save, cancel, deleteMutation.mutateAsync]}>
      {children}
    </EditContext.Provider>
  );
}