import { useContext } from 'react';
import { createNamedContext } from '@utils';
import type { GroupUser, WorkspaceObjectTreeItem, Workspace, WorkspaceObject } from '@/types';

export type ObjectOwnershipContextValue = {
  fetchUsers: () => Promise<unknown>;
  onUserSelect: (data: GroupUser) => unknown;
  save: () => Promise<unknown>;
  selectedUser: GroupUser;
  users: GroupUser[];
};

export type MoveObjectContextValue = {
  currentPath: string[];
  data: WorkspaceObjectTreeItem;
  object: WorkspaceObject;
  loadingTree?: boolean;
  onSave: () => Promise<unknown>;
  onSelectParentObject: (id: number) => unknown;
  parentObjectId: number;
};

export type MoveObjectWorkspaceContextValue = {
  loadingWorkspaces?: boolean;
  onSelectWorkspace: (value: Workspace) => unknown;
  selectedWorkspace: Workspace;
  workspaces: Workspace[];
};

export type UpdateObjectExpirationContextValue = {
  update: (data: { expiresOn: Date }) => Promise<unknown>;
};

export const MoveObjectWorkspaceContext = createNamedContext<MoveObjectWorkspaceContextValue>(undefined, 'move-object-workspace');
export const MoveObjectContext = createNamedContext<MoveObjectContextValue>(undefined, 'move-object');
export const ObjectOwnershipContext = createNamedContext<ObjectOwnershipContextValue>(undefined, 'object-ownership');
export const WorkspaceBreadcrumbsContext = createNamedContext<WorkspaceObject[]>(undefined, 'workspace-breadcrumbs');
export const UpdateObjectExpirationContext = createNamedContext<UpdateObjectExpirationContextValue>(undefined, 'UpdateObjectExpiration.Context');

export const useObjectBreadcrumbsContext = () => useContext(WorkspaceBreadcrumbsContext);