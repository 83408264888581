import http from '@services/http';
import type { Tags } from './interfaces';

export const getPersonTags = ({ userId }: Tags.GetGroupPersonTags.Request) => {
  return http.get(`/tags/group/person/${userId}`)
    .then((data: Tags.GetGroupPersonTags.Response) => ({
      tags: data.tags,
    }));
};

export const updatePersonTags = ({ userId, ...payload }: Tags.UpdateGroupPersonTags.Request) => {
  return http.put(`/tags/group/person/${userId}`, payload)
    .then((data: Tags.UpdateGroupPersonTags.Response) => ({
      tags: data.tags,
    }));
};

export const getFileTags = ({ fileId }: Tags.GetGroupFileTags.Request) => {
  return http.get(`/tags/group/file/${fileId}`)
    .then((data: Tags.GetGroupFileTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateFileTags = ({ fileId, ...payload }: Tags.UpdateGroupFileTags.Request) => {
  return http.put<Tags.UpdateGroupFileTags.Response>(`/tags/group/file/${fileId}`, payload);
};

export const massAddObjectTags = (data: Tags.MassAddObjectTags.Request) => {
  return http.put<Tags.MassAddObjectTags.Response>(`/tags/group/objects`, data);
};

export const syncClientObjectTags = ({ entityId, entityType, ...data }: Tags.SyncClientObjectTags.Request) => {
  return http.post(`/tags/group/entity/${entityType}/${entityId}/sync-client`, data);
};

export const syncClientObjectsTags = (data: Tags.SyncClientObjectsTags.Request) => {
  return http.post(`/tags/group/objects/sync-client`, data);
};

export const getCallTags = ({ callId }: Tags.GetGroupCallTags.Request) => {
  return http.get(`/tags/group/call/${callId}`)
    .then((data: Tags.GetGroupCallTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateCallTags = ({ callId, ...payload }: Tags.UpdateGroupCallTags.Request) => {
  return http.put<Tags.UpdateGroupCallTags.Response>(`/tags/group/call/${callId}`, payload);
};

export const syncClientCallTags = ({ callId, ...payload }: Tags.SyncClientCallTags.Request) => {
  return http.post(`/tags/group/call/${callId}/sync-client`, payload);
};

export const getSurveyResponseTags = ({ surveyResponseId }: Tags.GetGroupSurveyResponseTags.Request) => {
  return http.get(`/tags/group/survey-response/${surveyResponseId}`)
    .then((data: Tags.GetGroupSurveyResponseTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateSurveyResponseTags = ({ surveyResponseId, ...payload }: Tags.UpdateGroupSurveyResponseTags.Request) => {
  return http.put(`/tags/group/survey-response/${surveyResponseId}`, payload)
    .then((data: Tags.UpdateGroupSurveyResponseTags.Response) => ({
      tags: data.tags,
    }));
};

export const getTranscriptTags = ({ transcriptId }: Tags.GetGroupTranscriptTags.Request) => {
  return http.get(`/tags/group/transcript/${transcriptId}`)
    .then((data: Tags.GetGroupTranscriptTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateTranscriptTags = ({ transcriptId, ...payload }: Tags.UpdateGroupTranscriptTags.Request) => {
  return http.put<Tags.UpdateGroupTranscriptTags.Response>(`/tags/group/transcript/${transcriptId}`, payload);
};

export const getFolderTags = ({ folderId }: Tags.GetGroupFolderTags.Request) => {
  return http.get(`/tags/group/folder/${folderId}`)
    .then((data: Tags.GetGroupFolderTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateFolderTags = ({ folderId, ...payload }: Tags.UpdateGroupFolderTags.Request) => {
  return http.put<Tags.UpdateGroupFolderTags.Response>(`/tags/group/folder/${folderId}`, payload);
};

export const getProjectParentTags = ({ projectParentId }: Tags.GetGroupProjectParentTags.Request) => {
  return http.get(`/tags/group/project-parent/${projectParentId}`)
    .then((data: Tags.GetGroupProjectParentTags.Response) => ({
      tags: data.tags,
    }));
};

export const updateProjectParentTags = ({ projectParentId, ...payload }: Tags.UpdateGroupProjectParentTags.Request) => {
  return http.put<Tags.UpdateGroupProjectParentTags.Response>(`/tags/group/project-parent/${projectParentId}`, payload);
};