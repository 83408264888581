import { useMutation } from '@tanstack/react-query';
import type { WorkspaceObjectType } from '@/enums';
import { syncClientObjectTags } from '@api/tags-group';
import Toast from '@/components/Toast';
import type { SyncTagsMutationParams } from '../interfaces';

type Props = {
  entityId: number;
  entityType: WorkspaceObjectType;
};

export const useSyncClientObjectTagsMutation = (data: Props) => {
  return useMutation({
    mutationKey: ['sync-client-object-tags', data.entityId, data.entityType],
    mutationFn: (params: SyncTagsMutationParams) => syncClientObjectTags({ ...data, ...params }),
    onError: () => {
      Toast.error({
        title: `There was an error syncing the tags to the client`,
      });
    },
  });
};