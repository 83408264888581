import type { ApplySchemaAttributes, MarkExtensionSpec } from '@remirror/core';
import {
  helper,
  Helper,
  extension,
  ExtensionTag,
  MarkExtension,
} from '@remirror/core';
import { MarkType } from '@/types/transcribe.rich-text';
import classes from './style/AutoCorrect.css';

type Params = {
  enabled: boolean;
};

@extension({
  defaultOptions: {},
  disableExtraAttributes: true,
})
export class AutoCorrectExtension extends MarkExtension {
  get name() {
    return MarkType.AutoCorrect as const;
  }

  enabled: boolean;

  constructor(data: Params) {
    super();
    this.enabled = data.enabled;
  }

  createTags() {
    return [ExtensionTag.FormattingMark];
  }

  createMarkSpec(extra: ApplySchemaAttributes): MarkExtensionSpec {
    return {
      attrs: {
        id: { default: null },
      },
      parseDOM: [
        {
          tag: 'span',
          getAttrs: extra.parse,
        },
      ],
      toDOM: mark => {
        return ['span', {
          class: classes.autoCorrected,
          ...extra.dom(mark),
        }, 0];
      },
    };
  }

  @helper()
  isAutoCorrectEnabled(): Helper<boolean> {
    return this.enabled;
  }
}