import { useQuery } from '@tanstack/react-query';
import { getProjects } from '@/services/api/workspaces.brand';
import { useBrandId } from '../Context';

export const useBrandProjectsQuery = () => {
  const brandId = useBrandId();

  return useQuery({
    queryKey: ['get:brand-projects', brandId],
    queryFn: () => getProjects({ brandId }),
  });
};