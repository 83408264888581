import qs from 'query-string';
import http from '@/services/http';
import * as xform from '@transformers';
import { transformDates } from '@services/http/transformers';
import type { WorkspaceObjects as WS } from './interfaces';

export const downloadObjectRecursive = ({ workspaceId, objectId, ...rest }: WS.DownloadObjectRecursive.Request) => {
  return http.get<WS.DownloadObjectRecursive.Response>(`/workspaces/${workspaceId}/objects/${objectId}?${qs.stringify(rest, { arrayFormat: 'bracket' })}`);
};

export const downloadObjectRecursiveWithNotification = (data: WS.DownloadObjectRecursiveWithNotification.Request) => {
  return http.post<WS.DownloadObjectRecursiveWithNotification.Response>(`/workspaces/${data.workspaceId}/objects/${data.objectId}`, data);
};

export const getObjectAccess = ({ objectId, workspaceId }: WS.GetObjectAccess.Request) => {
  return http.get(`/workspaces/${workspaceId}/objects/${objectId}/access`)
    .then((data: WS.GetObjectAccess.Response) => ({
      items: data.items,
      object: xform.normalizeWorkspaceObject(data.object),
      workspace: xform.normalizeWorkspace(data.workspace),
    }));
};

export const moveObject = (data: WS.MoveObject.Request) => {
  return http.post(`/workspaces/${data.workspaceId}/objects/${data.objectId}/move`, {
    parentObjectId: data.parentObjectId,
  }).then((data: WS.MoveObject.Response) => ({
    object: xform.normalizeWorkspaceObject(data.object),
  }));
};

export const moveObjectDetails = (data: WS.GetMoveObjectDetails.Request) => {
  return http.get<WS.GetMoveObjectDetails.Response>(`/workspaces/objects/${data.objectId}/move`, {
    transformResponse: transformDates,
  });
};

export const saveObjectAccess = (data: WS.SaveObjectAccess.Request) => {
  return http.post(`/workspaces/${data.workspaceId}/objects/${data.objectId}/access`, {
    items: data.items,
  });
};

export const updateObjectExpiration = (data: WS.UpdateObjectExpiration.Request) => {
  return http.put(`/workspaces/${data.workspaceId}/objects/${data.objectId}/expiration`, {
    expiresOn: data.expiresOn,
  }).then((data: WS.UpdateObjectExpiration.Response<string>) => ({
    object: xform.normalizeWorkspaceObject(data.object),
  }));
};

export const updateObjectOwnership = (data: WS.UpdateObjectOwnership.Request) => {
  return http.post(`/workspaces/${data.workspaceId}/objects/${data.objectId}/owner`, {
    ownerId: data.ownerId,
  }).then((data: WS.UpdateObjectOwnership.Response) => ({
    object: xform.normalizeWorkspaceObject(data.object),
  }));
};

export const getObjectChildren = (data: WS.GetObjectChildren.Request): Promise<WS.GetObjectChildren.Response> => {
  const query = qs.stringify({
    filters: JSON.stringify(data.filters || {}),
  }, { arrayFormat: 'bracket' });
  return http.get(`/workspaces/${data.workspaceId}/objects/${data.parentObjectId}/children?${query}`, {
    transformResponse: transformDates,
  });
};

export const getSharedObjects = (data: WS.GetSharedObjects.Request): Promise<WS.GetSharedObjects.Response> => {
  const query = qs.stringify({
    parentObjectId: data.parentObjectId,
    filters: JSON.stringify(data.filters || {}),
  }, { arrayFormat: 'bracket' });
  return http.get(`/workspaces/shared/objects?${query}`, {
    transformResponse: transformDates,
  });
};