import { useContext, useState, useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { oxfordCommaJoin } from '@utils';
import { backfillBrandProjectAccess } from '@/services/api/workspaces.brand';
import { WorkspaceObjectRole, utils } from '@/enums';
import ActivityIndicator from '@/components/ActivityIndicator';
import { Header } from '@/components/Modal';
import { Buttons, Button } from '@/components/Button';
import { Select } from '@/components/Select';
import { ButtonActivityIndicator } from '@presentation/Button.ActivityIndicator';
import { BrandAccessStepperContext, useBrandId } from './Context';
import { useBrandProjectsQuery } from './hooks';
import type { IUser, ProjectItem } from './interfaces';
import styles from './styles/Brand.Access.Modal.css';

export const RemoveFromProjects = () => {
  const ctx = useContext(BrandAccessStepperContext);

  const projectsQuery = useBrandProjectsQuery();

  if (projectsQuery.isInitialLoading)
    return <ActivityIndicator show={true} />;

  if (!projectsQuery.data?.items?.length) {
    ctx.onSuccess();
    return null;
  }

  return <Content projects={projectsQuery.data.items} />;
};

type ContentProps = {
  projects: ProjectItem[];
};

const Content = (props: ContentProps) => {
  const ctx = useContext(BrandAccessStepperContext);
  const [roleState, setRoleState] = useState<Record<number, RemoveableRole>>(() => buildInitialRoleState(props.projects, ctx.users.inserted));
  const brandId = useBrandId();

  const onRoleChange = useCallback((project: ProjectItem, role: RemoveableRole) => {
    setRoleState(old => ({
      ...old,
      [project.id]: role,
    }));
  }, []);

  const saveMutation = useMutation({
    mutationFn: () => {
      return backfillBrandProjectAccess({
        brandId,
        users: ctx.users.deleted,
        projects: Object.entries(roleState).map(([k, v]) => ({ projectId: +k, roleId: v === RemoveableRole.Remove ? null : v })),
      });
    },
    onSuccess: () => {
      ctx.actions.next();
    },
  });

  return (
    <div className={styles.main}>
      <Header className={styles.header}>Remove Project Access?</Header>
      <div className={styles.subHeader}>Want to remove {oxfordCommaJoin(ctx.users.deleted.map(u => u.name))} from projects within this brand? Specify their access levels below to the projects within this brand.</div>
      <div className={styles.projectTable}>
        <div className={styles.tableHeader}>
          <div className={styles.projectCell}>
            Project
          </div>
          <div className={styles.roleCell}>
            Role
          </div>
        </div>
        <div className={styles.tableBody}>
          {props.projects.map(p => (
            <div key={p.id} className={styles.tableRow}>
              <div className={styles.projectCell}>{p.name}</div>
              <div className={styles.roleCell}>
                <Select
                  value={getRoleValue(roleState[p.id])}
                  options={VALID_ROLES}
                  onSelect={r => onRoleChange(p, r)}
                  getItemValue={getRoleValue} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <Buttons>
        <Button
          variant='brick'
          color='destructive'
          onClick={ctx.actions.next}>
          {`Skip`}
        </Button>
        <ButtonActivityIndicator
          variant='brick'
          color='affirmative'
          implicitDisable={false}
          loading={saveMutation.isLoading}
          onClick={() => saveMutation.mutate()}>
          Remove Access
        </ButtonActivityIndicator>
      </Buttons>
    </div>
  );
};

enum ExtendedRole {
  Remove = 0
}

type RemoveableRole = WorkspaceObjectRole | ExtendedRole;

const RemoveableRole = { ...ExtendedRole, ...WorkspaceObjectRole };

RemoveableRole.Remove;

const VALID_ROLES = [WorkspaceObjectRole.Viewer, WorkspaceObjectRole.Collaborator, ExtendedRole.Remove];

function getRoleValue(role: RemoveableRole) {
  if (role === RemoveableRole.Remove) return 'Remove';

  return utils.WorkspaceObjectRole.getName(role);
}

function buildInitialRoleState(projects: ProjectItem[], users: IUser[]): Record<number, RemoveableRole> {
  const defaultRole = ExtendedRole.Remove;
  const map: Record<number, RemoveableRole> = {};

  for (const p of projects) {
    map[p.id] = defaultRole;
  }

  return map;
}