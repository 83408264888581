import { StateRouter } from '@routes/Routers';
import { path } from '@consts';
import { RouteAuth } from '@enums';
import { BrandInsightsChat } from '@screens/BrandInsights.Chat';
import type * as Routing from '@routes/interfaces';
import { hasInsightsChatAccess, roles } from '@utils';

export const routes: Routing.Route[] = [
  {
    component: BrandInsightsChat,
    exact: true,
    path: [path.BrandInsights.Chat, path.BrandInsights.ChatInstance],
  },
];

const validator = (state: Store.State) => {

  const hasAccess = hasInsightsChatAccess(state.user, state.group);

  if (hasAccess) {
    return routes;
  }

  return [];
};

export const router = StateRouter(validator, {
  auth: RouteAuth.Authenticated,
  path: path.BrandInsights.Root,
});