import { useCallback, useState } from 'react';
import type { Group, GroupTag } from '@/types';
import type { ClientTagPublishModalProps } from '@/components/Group.Tags/ClientTagPublishModal';
import { useClientTagPublishModal } from '@/components/Group.Tags/ClientTagPublishModal';
import { GroupType } from '@/enums';
import { useIsInternalUser } from '@/containers/Store';

export const usePublishClientTags = () => {
  const [toggleModal, Modal] = useClientTagPublishModal();
  const isInternalUser = useIsInternalUser();

  const [tags, setTags] = useState<GroupTag[]>();
  const [groups, setGroups] = useState<Group[]>();

  const postSave = useCallback((data: PostSave) => {
    const newTags = data.tags.filter(t => !data.prevTags.map(x => x.id).includes(t.id));
    const validGroups = data.groups.filter(g => g.typeId === GroupType.Firm);

    if (newTags.length && validGroups.length && isInternalUser) {
      setTags(newTags);
      setGroups(validGroups);
      toggleModal();
    }
  }, [isInternalUser, toggleModal]);

  const PublishModal = useCallback((props: Omit<ClientTagPublishModalProps, 'open' | 'onClose' | 'tags' | 'groups'>) => {
    return <Modal
      {...props}
      tags={tags}
      groups={groups} />;
  }, [Modal, groups, tags]);

  return [postSave, PublishModal] as const;
};

type PostSave = {
  tags: GroupTag[];
  prevTags: GroupTag[];
  groups: Group[];
};