import { useContext, useState, useMemo, useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Buttons } from '@/components/Button';
import type { UseModalProps } from '@/components/Modal';
import { Modal, Header, useModal } from '@/components/Modal';
import { GroupTagSelect } from '@/components/AutoComplete/GroupTagSelect';
import type { Group, GroupTag, GroupTagUpdateItem } from '@/types';
import { ButtonActivityIndicator } from '@/presentation';
import { massAddObjectTags, syncClientObjectsTags } from '@api/tags-group';
import Toast from '@/components/Toast';
import { useInvalidateFilesQueries } from '@/utils/api';
import { useIsInternalUser } from '@/containers/Store';
import { PublishTagTable } from '@/components/Group.Tags/PublishTagTable';
import { useStepper, useToggle } from '@/utils';
import { useSelectedObjects } from './hooks';
import { ApplyTagsModalContext, SelectedObjectsContext } from './context';
import styles from './style/ApplyTags.Modal.css';

export const ApplyTagsModal = (props: UseModalProps) => {
  const [tags, setTags] = useState<GroupTagUpdateItem[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);

  const invalidateFilesQueryies = useInvalidateFilesQueries();
  const { clearSelectedObjects } = useContext(SelectedObjectsContext);

  const onDone = useCallback(() => {
    invalidateFilesQueryies();
    clearSelectedObjects();
    props.onClose();
  }, [clearSelectedObjects, invalidateFilesQueryies, props]);

  const [Screen, stepperActions] = useStepper([TagPickingScreen, AddToClientsScreen]);

  const ctxValue = useMemo(() => (
    {
      stepperActions,
      groups,
      setGroups,
      tags,
      setTags,
      onDone,
      onClose: props.onClose,
    }
  ), [groups, onDone, props.onClose, stepperActions, tags]);

  return (
    <ApplyTagsModalContext.Provider value={ctxValue}>
      <Modal disableOverlayClick={true} {...props}>
        <Screen />
      </Modal>
    </ApplyTagsModalContext.Provider>
  );
};

const TagPickingScreen = () => {
  const { onClose, onDone, stepperActions, setGroups, tags, setTags } = useModalContext();
  const selectedObjects = useSelectedObjects();
  const isInternal = useIsInternalUser();

  const addTagsMutation = useMutation({
    mutationFn: () => massAddObjectTags({ objectIds: selectedObjects.map(o => o.object.id), tags: tags, syncToClient: false }),
    onError: () => Toast.error({ title: 'Error adding tags' }),
    onSuccess: resp => {
      if (resp.groups.length && isInternal) {
        setGroups(resp.groups);
        stepperActions.next();
      } else {
        onDone();
      }
    },
  });

  return (
    <>
      <Header>{`Apply Tags to ${selectedObjects.length} selected objects`}</Header>
      <GroupTagSelect onChange={setTags} />
      <Buttons className={styles.buttons}>
        <Button
          variant='brick'
          color='destructive'
          onClick={onClose}>
          Cancel
        </Button>
        <ButtonActivityIndicator
          disabled={addTagsMutation.isLoading || !tags.length}
          implicitDisable={false}
          loading={addTagsMutation.isLoading}
          onClick={() => addTagsMutation.mutateAsync()}>
          Add Tags
        </ButtonActivityIndicator>
      </Buttons>
    </>
  );
};

const AddToClientsScreen = () => {
  const ctx = useModalContext();
  const [selectedTagIds, setSelectedTagIds] = useState(ctx.tags.map(t => t.id));
  const [selectedGroupIds, setSelectedGroupIds] = useState(ctx.groups.map(g => g.id));

  const selectedObjects = useSelectedObjects();

  const syncTagsToClientMutation = useMutation({
    mutationFn: () => syncClientObjectsTags({
      objects: selectedObjects.map(x => x.object),
      tags: ctx.tags.filter(t => selectedTagIds.includes(t.id)),
      groupIds: selectedGroupIds,
    }),
    onSuccess: ctx.onDone,
    onError: () => Toast.error({ title: 'There was an error syncing the tags to the client' }),
  });

  return (
    <>
      <Header>Add Tags to Client Accounts?</Header>
      <PublishTagTable
        tags={ctx.tags as GroupTag[]}
        groups={ctx.groups}
        selectedTagIds={selectedTagIds}
        setSelectedTagIds={setSelectedTagIds}
        selectedGroupIds={selectedGroupIds}
        setSelectedGroupIds={setSelectedGroupIds} />
      <Buttons>
        <Button
          variant='brick'
          color='destructive'
          onClick={ctx.onDone}>
          Cancel
        </Button>
        <ButtonActivityIndicator
          disabled={syncTagsToClientMutation.isLoading || !selectedTagIds.length || !selectedGroupIds.length}
          implicitDisable={false}
          loading={syncTagsToClientMutation.isLoading}
          onClick={() => syncTagsToClientMutation.mutateAsync()}>
          Add Tags
        </ButtonActivityIndicator>
      </Buttons>
    </>
  );
};

const useModalContext = () => useContext(ApplyTagsModalContext);

export const useApplyTagsModal = () => useModal(ApplyTagsModal);