import { useCallback, useContext } from 'react';
import { ArrowRight } from 'react-feather';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSurveyFormShowComplete } from '@containers/SurveyForm/hooks';
import { HideSurveyFormBackContext, useCanSubmitSurveyForm, useSurveyFormItemBack, useSurveyFormItemSubmit } from '@containers/SurveyForm/Context';
import {
  Back,
  CompleteButton,
  NextButton,
  WizardNavigation,
} from '@/components/Wizard';
import styles from './style.css';

const Navigation = () => {

  const back = useSurveyFormItemBack();
  const submit = useSurveyFormItemSubmit();
  const canSubmit = useCanSubmitSurveyForm();
  const showComplete = useSurveyFormShowComplete();

  const hideBackButton = useContext(HideSurveyFormBackContext);

  const renderBack = useCallback(() => {
    if (back.disabled || hideBackButton) {
      return null;
    }

    const disabled = back.isLoading || submit.isLoading;

    return (
      <Back
        disabled={disabled}
        onClick={back.handler} />
    );
  }, [
    back,
    hideBackButton,
    submit.isLoading,
  ]);

  const renderNext = useCallback(() => {
    const disabled = !canSubmit || back.isLoading;

    if (showComplete) {
      return (
        <CompleteButton
          disabled={disabled}
          implicitDisable={submit.isLoading}
          loading={submit.isLoading}
          onClick={() => submit.handler()} />
      );
    } else {
      return (
        <NextButton
          className={styles.nextBtn}
          disabled={disabled}
          implicitDisable={submit.isLoading}
          loading={submit.isLoading}
          onClick={() => submit.handler()}>
          Next <ArrowRight />
        </NextButton>
      );
    }
  }, [
    back.isLoading,
    canSubmit,
    submit,
    showComplete,
  ]);

  return (
    <WizardNavigation
      renderBack={renderBack}
      renderNext={renderNext} />
  );
};

export default Navigation;