import { Bell, Briefcase, Calendar, Home, Layers, LogOut, MessageSquare, Search, Settings, Share2, User } from 'react-feather';
import { createSelector } from '@reduxjs/toolkit';
import { menu } from '@consts';
import { Permission, Role } from '@enums';
import { InsightsChatIcon } from '@/components/icons';
import { access, hasClientRole, hasChatAccess, hasDataStewardRole, hasUniversalSearchAccess, hasInternalAdminAccess, hasInternalResearchAccess, hasPermission, hasInsightsChatOnlyRole, hasInsightsChatAccess } from '@utils';
import type { MenuIconMap } from './interfaces';

const selectRoles = (state: Store.State) => state.user.roles;
const selectPermissions = (state: Store.State) => state.user.permissions;
const selectGroupFeatures = (state: Store.State) => state.group.features;

const selectAdmin = createSelector(
  [selectPermissions],
  permissions => hasInternalAdminAccess({ permissions }),
);

const selectCalendar = createSelector(
  [selectRoles],
  roles => hasCommonAccess({ roles }),
);

const selectHome = createSelector(
  [selectRoles],
  roles => !hasDataStewardRole({ roles }) && !hasInsightsChatOnlyRole({ roles }),
);

const selectInsightsChat = createSelector(
  [selectRoles, selectPermissions, selectGroupFeatures],
  (roles, permissions, features) => {
    return hasInsightsChatAccess({ roles, permissions }, { features });
  },
);

const selectMessages = createSelector(
  [selectRoles, selectPermissions, selectGroupFeatures],
  (roles, permissions, groupFeatures) => hasChatAccess({ permissions, roles }, { features: groupFeatures }),
);

const selectNotifications = createSelector(
  [selectRoles],
  roles => hasCommonAccess({ roles }),
);

const selectProjects = createSelector(
  [selectRoles],
  roles => hasCommonAccess({ roles }),
);

const selectResearch = createSelector(
  [selectPermissions],
  permissions => hasInternalResearchAccess({ permissions }),
);

const selectSearch = createSelector(
  [selectRoles],
  roles => hasUniversalSearchAccess({ roles }),
);

export const memoizedSelectValidator = createSelector(
  [selectAdmin, selectCalendar, selectHome, selectInsightsChat, selectMessages, selectNotifications, selectProjects, selectResearch, selectSearch],
  (admin, calendar, home, insightsChat, messages, notifications, projects, research, search) => ({
    admin,
    calendar,
    home,
    insightsChat,
    messages,
    notifications,
    projects,
    research,
    search,
  }),
);

export const iconMap: MenuIconMap = Object.freeze({
  [menu.Admin]: Layers,
  [menu.Calendar]: Calendar,
  [menu.Home]: Home,
  [menu.InsightsChat]: InsightsChatIcon,
  [menu.Logout]: LogOut,
  [menu.Messages]: MessageSquare,
  [menu.Notifications]: Bell,
  [menu.Profile]: User,
  [menu.Projects]: Briefcase,
  [menu.Search]: Search,
  [menu.Settings]: Settings,
  [menu.Share]: Share2,
});

const hasCommonAccess = (user: Pick<Store.User, 'roles'>) => {
  return access.has.role.some(
    Role.Expert,
    Role.FirmCompliance,
    Role.FirmAnalyst,
    Role.InternalAdmin,
    Role.FirmTransient,
    Role.Recruiter,
  )(user.roles);
};