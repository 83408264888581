import type { Dispatch } from 'react';
import { useCallback } from 'react';
import type { Group, GroupTag } from '@/types';
import { Checkbox } from '@/components/Checkbox';
import styles from './styles.css';

type TagIdentifier = string | number;

type Props = {
  tags: GroupTag[];
  selectedTagIds: TagIdentifier[];
  setSelectedTagIds: Dispatch<React.SetStateAction<TagIdentifier[]>>;
  groups: Group[];
  selectedGroupIds: number[];
  setSelectedGroupIds: Dispatch<React.SetStateAction<number[]>>;
};

export const PublishTagTable = ({ tags, selectedTagIds, setSelectedTagIds, groups, selectedGroupIds, setSelectedGroupIds }: Props) => {

  const toggleTag = useCallback((id: number) => {
    setSelectedTagIds(old => {
      if (old.includes(id)) {
        return old.filter(x => x !== id);
      } else {
        return [...old, id];
      }
    });
  }, [setSelectedTagIds]);

  const toggleAccount = useCallback((id: number) => {
    setSelectedGroupIds(old => {
      if (old.includes(id)) {
        return old.filter(x => x !== id);
      } else {
        return [...old, id];
      }
    });
  }, [setSelectedGroupIds]);

  return (
    <>
      <div className={styles.table}>
        <div className={styles.tableHeader}>Tags ({selectedTagIds.length} Selected)</div>
        {tags.map(t => (
          <div key={t.id} className={styles.tableRow}>
            <Checkbox checked={selectedTagIds.includes(t.id)} onClick={() => toggleTag(t.id)} />
            <div>{t.name}</div>
          </div>
        ))}
      </div>
      <div className={styles.table}>
        <div className={styles.tableHeader}>Client Accounts ({selectedGroupIds.length} Selected)</div>
        {groups.map(g => (
          <div key={g.id} className={styles.tableRow}>
            <Checkbox checked={selectedGroupIds.includes(g.id)} onClick={() => toggleAccount(g.id)} />
            <div>{g.name}</div>
          </div>
        ))}
      </div>
    </>
  );
};