import { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import * as $api from '@api';
import { WorkspaceObjectType } from '@/enums';
import {
  GroupEntityTagsContext,
  GroupEntityTagsDispatchContext,
  GroupEntityTagsFetchContext,
  GroupEntityTagsLoadingContext,
  GroupEntityTagsUpdateContext,
} from './Context';
import type { GroupTagsContextValue, GroupTagUpdateItem } from './interfaces';
import { usePublishClientTags, useSyncClientObjectTagsMutation } from './hooks';

type Props = IWorkspaceFolderId & ChildrenProps;

export default function GroupFolderTagsContainer({ children, folderId }: Props) {
  const [state, dispatch] = useState<GroupTagsContextValue>(GroupFolderTagsContainer.initialState);

  const { isSuccess, refetch, data } = useQuery({
    queryKey: ['get:group/tags/folder', folderId],
    queryFn: () => {
      return $api.tags.group.getFolderTags({ folderId });
    },
  });

  useEffect(() => {
    if (data?.tags) {
      dispatch(data.tags);
    }
  }, [data?.tags]);

  const [postSave, SyncModal] = usePublishClientTags();
  const syncTagsMutation = useSyncClientObjectTagsMutation({ entityId: folderId, entityType: WorkspaceObjectType.Folder });

  const { mutateAsync } = useMutation({
    mutationFn: (tags: GroupTagUpdateItem[]) => {
      return $api.tags.group.updateFolderTags({
        folderId,
        tags,
      });
    },
    onSuccess: result => {
      postSave({ prevTags: state, ...result });
      dispatch(result.tags);
    },
  });

  return (
    <GroupEntityTagsContext.Provider value={state}>
      <GroupEntityTagsLoadingContext.Provider value={!isSuccess}>
        <GroupEntityTagsFetchContext.Provider value={refetch}>
          <GroupEntityTagsUpdateContext.Provider value={mutateAsync}>
            <GroupEntityTagsDispatchContext.Provider value={dispatch}>
              {children}
              <SyncModal mutation={syncTagsMutation} />
            </GroupEntityTagsDispatchContext.Provider>
          </GroupEntityTagsUpdateContext.Provider>
        </GroupEntityTagsFetchContext.Provider>
      </GroupEntityTagsLoadingContext.Provider>
    </GroupEntityTagsContext.Provider>
  );
}

const initial: GroupTagsContextValue = [];

GroupFolderTagsContainer.initialState = initial;

export { GroupFolderTagsContainer };