import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import type * as API from '@api/interfaces';
import * as QK from '@consts/querykey';
import { useInvalidateFilePreviewQueries, useInvalidateFilesQueries, useInvalidateObjectChildrenQuery } from '@utils/api';
import { useUpdateObjectExpiration } from './hooks';
import { UpdateObjectExpirationContext } from './Context';

type Props = {
  children: React.ReactNode;
} & Partial<IProjectId>
  & IWorkspaceFileId
  & IWorkspaceObjectId
  & IWorkspaceId;

export const UpdateObjectExpirationContainer = (props: Props) => {
  const updateExpiration = useUpdateObjectExpiration();
  const invalidateObjectQueries = useInvalidateObjectChildrenQuery();
  const invalidateFilesQueries = useInvalidateFilesQueries();
  const invalidateFilePreviewQueries = useInvalidateFilePreviewQueries();
  const qc = useQueryClient();

  const key = useMemo(() => {
    if (props.projectId) {
      return QK.Projects.FilePreview.Get({
        fileId: props.fileId,
        projectId: props.projectId,
      });
    }

    return QK.Workspaces.FilePreview.Get({
      fileId: props.fileId,
    });
  }, [
    props.fileId,
    props.projectId,
  ]);

  const setQueryData = useCallback((expiresOn: Date) => {
    qc.setQueryData(key, (data: API.WorkspaceFiles.GetFilePreview.Response) => {
      return {
        ...data,
        object: {
          ...data.object,
          expiresOn,
        }
      };
    });
  }, [
    key,
    qc,
  ]);

  const handleUpdate = useCallback(async (data: { expiresOn: Date }) => {
    setQueryData(data.expiresOn);

    const res = await updateExpiration({
      objectId: props.objectId,
      expiresOn: data.expiresOn,
      workspaceId: props.workspaceId,
    })

    invalidateObjectQueries();
    invalidateFilePreviewQueries({ fileId: props.fileId });
    invalidateFilesQueries();
  }, [
    invalidateFilePreviewQueries,
    invalidateFilesQueries,
    invalidateObjectQueries,
    props.fileId,
    props.objectId,
    props.workspaceId,
    setQueryData,
    updateExpiration,
  ]);

  const value = {
    update: handleUpdate,
  };

  return (
    <UpdateObjectExpirationContext.Provider value={value}>
      {props.children}
    </UpdateObjectExpirationContext.Provider>
  );
};