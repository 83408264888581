import http from '@services/http';
import { transformDates } from '@services/http/transformers';
import * as xform from '@transformers';
import type { WorkspaceBrand as WS } from './interfaces';

export const getBrand = (data: WS.GetBrand.Request): Promise<WS.GetBrand.Response> => {
  return http.get(`/workspaces/brands/${data.brandId}`, {
    transformResponse: transformDates,
  });
};

export const getProjects = (data: WS.GetProjects.Request) => {
  const { brandId, ...params } = data;

  return http.get(`/workspaces/brands/${data.brandId}/projects`, {
    params,
  })
    .then((res: WS.GetProjects.Response<string>) => ({
      items: res.items.map<WS.GetProjects.ProjectItem>(x =>
        //@ts-ignore
        xform.normalizeProjectDates(x)
      ),
      pagination: res.pagination,
    }));
};

export const getProjectTracker = (data: WS.GetProjectTracker.Request): Promise<WS.GetProjectTracker.Response> => {
  return http.get(`/workspaces/brands/${data.brandId}/project-tracker`, {
    transformResponse: transformDates,
  });
};

export const getBrands = (data: WS.GetBrands.Request = {}) => {
  return http.get<WS.GetBrands.Response>(`/workspaces/brands`, { params: data, transformResponse: transformDates });
};

export const getBrandAccess = (data: WS.GetBrandAccess.Request) => {
  return http.get<WS.GetBrandAccess.Response>(`/workspaces/brands/${data.brandId}/access`);
};

export const updateBrandAccess = ({ brandId, ...data }: WS.UpdateBrandAccess.Request) => {
  return http.post<WS.UpdateBrandAccess.Response>(`/workspaces/brands/${brandId}/access`, data);
};

export const backfillBrandProjectAccess = ({ brandId, ...data }: WS.BackfillBrandProjectAccess.Request) => {
  return http.post<WS.BackfillBrandProjectAccess.Response>(`/workspaces/brands/${brandId}/backfill-project-access`, data, { transformResponse: transformDates });
};

export const backfillBrandCallAccess = ({ brandId, ...data }: WS.BackfillBrandCallAccess.Request) => {
  return http.post<WS.BackfillBrandCallAccess.Response>(`/workspaces/brands/${brandId}/backfill-call-access`, data, { transformResponse: transformDates });
};