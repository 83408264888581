import { useCallback, useContext, useState, useMemo } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { useStepper } from '@utils';
import { useIsInternalUser } from '@/containers/Store/hooks';
import { backfillBrandCallAccess } from '@api/workspaces.brand';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import type { ModalProps } from '@/components/Modal/Modal';
import Toast from '@/components/Toast';
import { useModal, Header, Modal as RootModal } from '@/components/Modal';
import { SelectUpcomingCalls } from '@/components/Project.Access/SelectUpcomingCalls';
import type { BackfillBrandProjectAccess } from '@/services/api/interfaces/workspace.brand';
import { ReadOnlyProjectAccess } from '$admin/components/Project.Access';
import { AccessTable } from './AccessTable';
import { AddToProjects } from './Brand.Access.AddToProjects';
import { AddToUpcomingCallsConfirmation } from './Brand.Access.CallsConfirmation';
import { BrandAccessMutationContext, BrandAccessQueryContext, BrandAccessStepperContext, useBrandId } from './Context';
import type { IUser, UpcomingCall, AccessMutationResult } from './interfaces';
import styles from './styles/Brand.Access.Modal.css';
import { RemoveFromProjects } from './Brand.Access.RemoveFromProjects';

type Props = {
  onSuccess?: () => unknown;
} & Pick<ModalProps, 'onClose' | 'open'>;

export const BrandAccessModal = (props: Props) => {
  const [users, setUsers] = useState<AccessMutationResult>();
  const screens = useMemo(() => {
    const screens = [ManageAccess];

    if (users?.deleted?.length) {
      screens.push(RemoveFromProjects);
    }

    if (users?.inserted?.length) {
      screens.push(...[AddToProjects, AddToUpcomingCallsConfirmation, AddToUpcomingCalls]);
    }

    return screens;
  }, [users?.deleted?.length, users?.inserted?.length]);
  const [Component, stepperActions, stepperIndex] = useStepper(screens);
  const query = useContext(BrandAccessQueryContext);
  const [upcomingCalls, setUpcomingCalls] = useState<UpcomingCall[]>();
  const [projectMutationResult, setProjectMutationResult] = useState<BackfillBrandProjectAccess.Response>();

  const onSuccess = useCallback(() => {
    props.onSuccess?.();
    props.onClose();
  }, [props]);

  const actions = useMemo(() => ({
    ...stepperActions,
    next: stepperIndex >= screens.length - 1 ? onSuccess : stepperActions.next,
    forceNext: stepperActions.next,
  }), [onSuccess, screens.length, stepperActions, stepperIndex]);

  const ctxValue = useMemo(() => ({
    actions,
    onClose: props.onClose,
    onSuccess,
    items: query.data?.access ?? [],
    users,
    setUsers,
    upcomingCalls,
    setUpcomingCalls,
    projectMutationResult,
    setProjectMutationResult,
  }), [actions, props.onClose, onSuccess, query.data?.access, users, upcomingCalls, projectMutationResult]);

  if (query.isInitialLoading) {
    return null;
  }

  return (
    <BrandAccessStepperContext.Provider value={ctxValue}>
      <RootModal
        classes={{ root: styles.modal }}
        disableEscapeClose
        disableOverlayClick
        onClose={props.onClose}
        open={props.open}>
        <div className={styles.root}>
          <div className={styles.wrap}>
            <Component />
          </div>
        </div>
      </RootModal>
    </BrandAccessStepperContext.Provider>
  );
};

const ManageAccess = () => {
  const { actions, setUsers, onSuccess, onClose, ...ctx } = useContext(BrandAccessStepperContext);

  const [items, setItems] = useState(ctx.items);
  const mutation = useContext(BrandAccessMutationContext);

  const isInternalUser = useIsInternalUser();

  const handleSubmit = useCallback(() => {
    mutation.mutateAsync(items).then(result => {
      if (result.inserted.length || result.deleted.length) {
        setUsers({
          inserted: result.inserted,
          deleted: result.deleted,
        });
        //Use forceNext since the stepper screens won't have changed yet and itll try and close the modal
        actions.forceNext();
      } else {
        onSuccess();
      }
    });
  }, [actions, items, mutation, onSuccess, setUsers]);

  const disabled = useMemo(() => {
    return mutation.isLoading;
  }, [mutation.isLoading]);

  return (
    <>
      <div className={styles.main}>
        <Header className={styles.header}>Manage Access</Header>
        {isInternalUser ? (
          <AccessTable
            items={items}
            updater={setItems} />
        ) : <ReadOnlyProjectAccess items={items} />}
      </div>
      {isInternalUser && (
        <ButtonSet className={styles.footer}>
          <ButtonOutlined
            className={styles.btn}
            color="black"
            disabled={disabled}
            fontWeight="bold"
            onClick={onClose}>
            Cancel
          </ButtonOutlined>
          <ButtonActivityIndicator
            className={styles.btn}
            disabled={disabled}
            implicitDisable={false}
            loading={mutation.isLoading}
            onClick={handleSubmit}>
            Save
          </ButtonActivityIndicator>
        </ButtonSet>
      )}
    </>
  );
};

const AddToUpcomingCalls = () => {
  const { actions, onSuccess, upcomingCalls, ...ctx } = useContext(BrandAccessStepperContext);
  const [selectedCalls, setSelectedCalls] = useState(upcomingCalls.map(c => c.callId));
  const brandId = useBrandId();

  const { mutate: backfill, isLoading } = useMutation({
    mutationFn: () => {
      return backfillBrandCallAccess({
        callIds: selectedCalls,
        users: ctx.users.inserted,
        brandId,
      });
    },
    onSuccess: ctx.onClose,
    onError: () => {
      Toast.error({ title: 'There was an error adding the users to the calls' });
    },
  });

  return (
    <>
      <div className={styles.main}>
        <Header className={styles.header}>Upcoming Calls</Header>
        <div>Assign the participants to the upcoming calls</div>
        <SelectUpcomingCalls
          calls={upcomingCalls}
          selectedCallIds={selectedCalls}
          setSelectedCallIds={setSelectedCalls} />
      </div>
      <ButtonSet className={styles.footer}>
        <ButtonOutlined
          className={styles.btn}
          color="black"
          fontWeight="bold"
          onClick={ctx.onClose}>
          Cancel
        </ButtonOutlined>
        <ButtonActivityIndicator
          loading={isLoading}
          className={styles.btn}
          onClick={() => backfill()}>
          Save
        </ButtonActivityIndicator>
      </ButtonSet>
    </>
  );
};

BrandAccessModal.displayName = 'Brand.Access.Modal';

export const useBrandAccessModal = () => useModal(BrandAccessModal);