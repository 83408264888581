import { useCallback, useState, useContext, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { querykey, vancery } from '@consts';
import * as api from '@api';
import { WorkspaceObjectRole } from '@enums';
import { ProjectAccess } from '@/access/admin/components/Project.Access';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { groupBy } from '@/utils/array';
import { Header } from '@/components/Modal';
import { ButtonSet } from '@/components/Modal/ButtonSet';
import ActivityIndicator from '@/components/ActivityIndicator';
import type { Entry } from '@/components/Project.Access/interfaces';
import { FilesTableContext } from './context';
import type { PublishScreenProps } from './interfaces';
import { usePublishModalNavigateNext } from './PublishModal.Navigation';
import styles from './style/PublishModal.css';

export const Access = (props: PublishScreenProps) => {
  const { projectId } = useContext(FilesTableContext);
  const hiddenItems = useRef<Entry[]>([]);
  const accessQuery = useQuery({
    queryKey: [querykey.Projects.Access.Get, projectId], queryFn: () => {
      return api.projects.access.getProjectAccessUsers({ projectId });
    }, refetchOnMount: false, refetchOnWindowFocus: false, refetchOnReconnect: false,
  });

  const updateAccessMutation = useMutation({
    mutationFn: () => {
      return api.projects.access.updateProjectAccessUsers({
        items: [...accessState, ...hiddenItems.current],
        projectId,
      });
    },
  });

  const [accessState, setAccessState] = useState<Entry[]>([]);

  useEffect(() => {
    if (accessQuery.data) {
      setAccessState(old => {
        if (old.length) {
          return old;
        } else {
          //Only let the user manage client like users, other users will get added back to the mutation later
          const grouped = groupBy(accessQuery.data.items, k => (vancery.internalEmailDomains.some(d => k.email.endsWith(d))) ? 'hide' : 'show');
          hiddenItems.current = grouped['hide'] ?? [];
          return transformInitialState(grouped['show'] ?? [], accessQuery.data.groupUserIds);
        }
      });
    }
  }, [accessQuery.data]);

  const handleNext = usePublishModalNavigateNext(props.onClose);

  const handleSubmit = useCallback(() => {
    updateAccessMutation.mutateAsync()
      .then(res => handleNext());
  }, [
    handleNext,
    updateAccessMutation,
  ]);

  if (accessQuery.isInitialLoading) return <ActivityIndicator />;

  if (!accessState.length) {
    return (
      <div>
        There are no users to grant access to.
      </div>
    );
  }

  return (
    <>
      <Header>Grant Clients Access?</Header>
      <div className={styles.subheader}>Manage who can view the published transcripts. Granting access will allow clients to access the project, view, and download published transcripts.</div>
      <ProjectAccess
        items={accessState}
        optionsType="TranscriptPublishing"
        updater={entries => setAccessState(entries)}
        showPrimary={false} />
      <ButtonSet className={styles.footer}>
        <ButtonOutlined
          className={styles.btn}
          color="black"
          disabled={updateAccessMutation.isLoading}
          fontWeight="bold"
          onClick={props.onClose}>
          Cancel
        </ButtonOutlined>
        <ButtonActivityIndicator
          color="affirmative"
          className={styles.btn}
          implicitDisable={false}
          loading={updateAccessMutation.isLoading}
          onClick={handleSubmit}>
          Grant Access
        </ButtonActivityIndicator>
      </ButtonSet>
    </>
  );
};

function transformInitialState(items: Entry[] = [], groupUserIds: number[] = []): Entry[] {
  return items.map(x => {
    if (x.offPlatform) {
      return x;

    } else if (groupUserIds.some(userId => userId === x.id)) {
      return {
        ...x,
        roleId: WorkspaceObjectRole.Viewer,
      };
    } else {
      return x;
    }
  });
}