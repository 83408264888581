import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as api from '@api';
import { useAsyncStateLazy } from '@utils';
import { useInvalidateProjectFilesQuery } from '@/utils/api';
import Toast from '@/components/Toast';
import {
  GroupEntityTagsContext,
  GroupEntityTagsDispatchContext,
  GroupEntityTagsFetchContext,
  GroupEntityTagsLoadingContext,
  GroupEntityTagsUpdateContext,
} from './Context';
import { usePublishClientTags } from './hooks';
import type { GroupTagsContextValue, GroupTagUpdateItem, GroupCallTagsProps, SyncTagsMutationParams } from './interfaces';

export default function GroupCallTagsContainer(props: GroupCallTagsProps) {
  const [state, dispatch] = useState<GroupTagsContextValue>(GroupCallTagsContainer.initialState);

  const [response, fetch] = useAsyncStateLazy(async () => {
    const result = await api.tags.group.getCallTags({
      callId: props.callId,
    });

    dispatch(result.tags);

    return result.tags;
  }, [props.callId]);

  const invalidateFiles = useInvalidateProjectFilesQuery();
  const [postSave, ClientPublishModal] = usePublishClientTags();

  const update = useCallback(async (tags: GroupTagUpdateItem[]) => {
    const result = await api.tags.group.updateCallTags({
      tags,
      callId: props.callId,
    });
    postSave({ ...result, prevTags: state });
    dispatch(result.tags);
    invalidateFiles();
  }, [invalidateFiles, postSave, props.callId, state]);

  useEffect(() => {
    if (props.callId) {
      fetch();
    }
  }, [fetch, props.callId]);

  const syncTagsMutation = useMutation({
    mutationFn: (params: SyncTagsMutationParams) => api.tags.group.syncClientCallTags({ callId: props.callId, ...params }),
    onError: () => Toast.error({ title: 'There was an error syncing the tags to the client' }),
  });

  return (
    <GroupEntityTagsContext.Provider value={state}>
      <GroupEntityTagsLoadingContext.Provider value={response.loading}>
        <GroupEntityTagsFetchContext.Provider value={fetch}>
          <GroupEntityTagsUpdateContext.Provider value={update}>
            <GroupEntityTagsDispatchContext.Provider value={dispatch}>
              {props.children}
              <ClientPublishModal mutation={syncTagsMutation} />
            </GroupEntityTagsDispatchContext.Provider>
          </GroupEntityTagsUpdateContext.Provider>
        </GroupEntityTagsFetchContext.Provider>
      </GroupEntityTagsLoadingContext.Provider>
    </GroupEntityTagsContext.Provider>
  );
}

const initial: GroupTagsContextValue = [];

GroupCallTagsContainer.initialState = initial;

export { GroupCallTagsContainer };