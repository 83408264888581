import { useCallback } from 'react';
import * as api from '@api';
import type * as API from '@api/interfaces';
import Toast from '@/components/Toast';

export const useUpdateObjectExpiration = () => {
  return useCallback((data: API.WorkspaceObjects.UpdateObjectExpiration.Request) => {
    return api.workspaces.object.updateObjectExpiration({
      expiresOn: data.expiresOn,
      objectId: data.objectId,
      workspaceId: data.workspaceId,
    })
    .then(res => {
      Toast.alert({
        title: 'Expiration Date Updated',
      });
      return res;
    })
    .catch(e => {
      Toast.error({
        title: 'Error',
        body: `We're sorry, there was an issue with your request`,
      });
      throw e;
    });
  }, []);
};