import { useContext, useCallback, useState } from 'react';
import { ButtonActivityIndicator, ButtonOutlined } from '@presentation';
import { Buttons } from '@/components/Button';
import { Header } from '@/components/Modal';
import { Checkbox } from '@/components/Checkbox';
import { BrandAccessStepperContext } from './Context';
import styles from './styles/Brand.Access.Modal.css';

export const AddToUpcomingCallsConfirmation = () => {
  const { actions, onSuccess, projectMutationResult, ...ctx } = useContext(BrandAccessStepperContext);
  const [selectedProjectIds, setSelectedProjectIds] = useState<number[]>(Object.keys(projectMutationResult.projectNames).map(parseInt));
  const projectCount = Object.keys(projectMutationResult.projectNames).length;

  const toggleProjectSelect = useCallback((projectId: number) => {
    setSelectedProjectIds(old => {
      if (old.includes(projectId)) {
        return old.filter(x => x != projectId);
      } else {
        return [...old, projectId];
      }
    });
  }, [setSelectedProjectIds]);

  const toggleMasterCallSelect = useCallback(() => {
    setSelectedProjectIds(old => {
      if (old.length === selectedProjectIds.length) {
        return [];
      } else {
        return Object.keys(projectMutationResult.projectNames).map(parseInt);
      }
    });
  }, [projectMutationResult.projectNames, selectedProjectIds.length]);

  const onNext = useCallback(() => {
    ctx.setUpcomingCalls(projectMutationResult.upcomingCalls.filter(c => selectedProjectIds.includes(c.projectId)));
    actions.next();
  }, [actions, ctx, projectMutationResult.upcomingCalls, selectedProjectIds]);

  return (
    <>
      <div className={styles.main}>
        <Header className={styles.header}>Add to Upcoming Calls</Header>
        <div>Do you want to add the members to all the upcoming calls?</div>
      </div>
      <div className={styles.projectNamesTable}>
        <div className={styles.tableHeader}>
          <div className={styles.checkboxCell}>
            <Checkbox
              checked={selectedProjectIds.length === projectCount}
              indeterminate={(!!selectedProjectIds.length && selectedProjectIds.length != projectCount)}
              onClick={toggleMasterCallSelect} />
          </div>
          <div className={styles.projectNameCell}>Project Name</div>
        </div>
        <div className={styles.tableBody}>
          {Object.entries(projectMutationResult.projectNames).map(([k, name]) => (
            <div key={k} className={styles.tableRow}>
              <div className={styles.checkboxCell}>
                <Checkbox checked={selectedProjectIds.includes(+k)} onChange={e => toggleProjectSelect(+k)} />
              </div>
              <div className={styles.projectNameCell}>
                {name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Buttons className={styles.footer}>
        <ButtonOutlined
          className={styles.btn}
          color="black"
          fontWeight="bold"
          onClick={ctx.onClose}>
          Do Not Add
        </ButtonOutlined>
        <ButtonOutlined
          className={styles.btn}
          disabled={!selectedProjectIds.length}
          onClick={onNext}>
          Add To Upcoming Calls
        </ButtonOutlined>
      </Buttons>
    </>
  );
};